import service from "@/api/service";
import { PaidTicketGeneralGetRequest } from "@/api/analysis/paid-ticket-general/request";
import { DownloadCSVResponse } from "@/api/response";

/**
 * デジタルチケット全体分析CSV出力APIをコールします。
 *
 * @param getRequest デジタルチケット全体分析CSV出力APIのリクエストボディ
 * @return response
 */
export async function get(getRequest: PaidTicketGeneralGetRequest) {
  const response = await service.postReceiveCSV(
    "/analysis-paid-ticket-general-csv",
    getRequest
  );
  return response as DownloadCSVResponse;
}
