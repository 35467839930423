import { Component, Mixins, Watch } from "vue-property-decorator";
import AnalysisBase from "@/models/analysis/analysis-base";
import UlContentHeader from "@/components/UlContentHeader.vue";
import Flash, { ErrorAlert } from "@/store/common/flash";
import ShopGet from "@/store/shop/get";
import PaidTicketInfoGet from "@/store/analysis/paid-ticket-info/get";
import PaidTicketGeneralGet from "@/store/analysis/paid-ticket-general/get";
import Admin from "@/store/admin/admin";
import { PaidTicketInfoGetItem } from "@/api/analysis/paid-ticket-info/response";
import * as PaidTicketGeneralCSV from "@/api/analysis/paid-ticket-general-csv";
import { PaidTicketGeneralGetRequest } from "@/api/analysis/paid-ticket-general/request";

export interface PaidTicketGeneralInputOptions {
  selectShop: { id: string; name: string } | null;
  selectTicket: { id: number; name: string } | null;
}

@Component({
  components: {
    UlContentHeader
  }
})
export default class AnalysisPaidTicketPeriod extends Mixins(AnalysisBase) {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "デジタルチケット全体分析";
  headingSub = "Digital ticket";
  breadCrumbs = [{ text: "デジタルチケット全体分析", disabled: true }];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoadedShopData = false;
  isLoadedTicketData = false;
  isLoadedPaidTicketGeneralData = false;

  // 検索入力オプション
  paidTicketGeneralInputOptions: PaidTicketGeneralInputOptions = {
    selectShop: null,
    selectTicket: null
  };

  selectShopId = "";

  selectedTicket: PaidTicketInfoGetItem = {} as PaidTicketInfoGetItem;

  @Watch("paidTicketGeneralInputOptions", { deep: true })
  paidTicketInputOptionsDidChange(inputOptions: PaidTicketGeneralInputOptions) {
    if (inputOptions.selectTicket == null) {
      this.selectedTicket = {} as PaidTicketInfoGetItem;
      return;
    }
    const selectedTicket = this.ticketItems.find(t => {
      return (
        (inputOptions.selectTicket != null
          ? inputOptions.selectTicket.id
          : null) == t.id
      );
    });
    if (selectedTicket) {
      this.selectedTicket = selectedTicket;
    }
  }

  @Watch("paidTicketGeneralInputOptions.selectShop")
  async selectShopDidChange(selectShop: { id: string; name: string } | null) {
    this.paidTicketGeneralInputOptions.selectTicket = null;
    this.isLoadedPaidTicketGeneralData = false;

    if (selectShop) {
      await this.fetchTicketList();
    } else {
      await PaidTicketInfoGet.clearResponse();
    }
  }

  @Watch("paidTicketGeneralInputOptions.selectTicket")
  selectTicketDidChange(newValue: { id: number; name: string } | null) {
    if (newValue == null) {
      this.isLoadedPaidTicketGeneralData = false;
    }
  }

  get shopItems() {
    return ShopGet.getItems;
  }

  get ticketItems() {
    return PaidTicketInfoGet.getItems;
  }

  get formattedPaidTicketGeneralItem() {
    return PaidTicketGeneralGet.getFormattedItem;
  }

  get genderData() {
    return PaidTicketGeneralGet.getGenderData;
  }

  get periodData() {
    return PaidTicketGeneralGet.getPeriodData;
  }

  get shopData() {
    return PaidTicketGeneralGet.getShopData;
  }

  get ticketTableHeaders() {
    let headers: {}[] = [];
    if (this.selectedTicket.ticketType == 1) {
      headers = [
        { text: "累計販売枚数", value: "totalCount" },
        { text: "累計売上", value: "totalSales" },
        { text: "チケット単価", value: "unitPrice" },
        { text: "チケット利用率", value: "ticketUseRate" },
        { text: "チケット無効率", value: "ticketInvalidRate" },
        { text: "チケット再購入率", value: "ticketRepurchaseRate" }
      ];
    } else if (this.selectedTicket.ticketType == 2) {
      headers = [
        { text: "累計販売枚数", value: "totalCount" },
        { text: "累計売上", value: "totalSales" },
        { text: "チケット利用回数実績", value: "useData" },
        { text: "チケット無効率", value: "ticketInvalidRate" },
        { text: "チケット再購入率", value: "ticketRepurchaseRate" }
      ];
    } else if (
      this.selectedTicket.ticketType == 3 &&
      this.selectedTicket.subscriptionType == 1
    ) {
      headers = [
        { text: "現状の契約者数", value: "currentUserValue" },
        { text: "累計契約者数", value: "totalUserValue" },
        { text: "累計売上", value: "totalSales" }
      ];
    } else if (
      this.selectedTicket.ticketType == 3 &&
      this.selectedTicket.subscriptionType == 2
    ) {
      headers = [
        { text: "累計販売枚数", value: "totalCount" },
        { text: "累計売上", value: "totalSales" },
        { text: "チケット利用回数実績", value: "useData" },
        { text: "チケット無効率", value: "ticketInvalidRate" },
        { text: "チケット再購入率", value: "ticketRepurchaseRate" }
      ];
    }

    return headers;
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (this.editFocused || !this.isNeededActiveOnDashBoard) {
      return false;
    }

    await this.fetchShopList();
    if (!Admin.isAdmin) {
      this.paidTicketGeneralInputOptions.selectShop = ShopGet.getItems[0];
      await this.fetchTicketList();
      this.paidTicketGeneralInputOptions.selectTicket = {
        id: PaidTicketInfoGet.getItems[0].id,
        name: PaidTicketInfoGet.getItems[0].title
      };
      this.selectedTicket = this.ticketItems[0];
      this.fetchPaidTicketGeneral();
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopGet.clearResponse();
    await PaidTicketInfoGet.clearResponse();
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    this.isLoadedShopData = false;
    await ShopGet.get();
    if (!ShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedShopData = true;
    return ShopGet.isSuccess;
  }

  async fetchTicketListAndPaidTicketGeneral() {
    await this.fetchPaidTicketGeneral();
  }

  /**
   * デジタルチケット情報の一覧を取得する処理
   */
  async fetchTicketList(): Promise<boolean> {
    this.isLoadedTicketData = false;
    await Flash.clear();

    await PaidTicketInfoGet.get({
      shopId:
        this.paidTicketGeneralInputOptions.selectShop != null
          ? Number(this.paidTicketGeneralInputOptions.selectShop.id)
          : null
    });
    if (!PaidTicketInfoGet.isSuccess) {
      await Flash.setErrorNow({
        message: PaidTicketInfoGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedTicketData = true;
    return PaidTicketInfoGet.isSuccess;
  }

  async fetchPaidTicketGeneral() {
    this.isLoadedPaidTicketGeneralData = false;
    await Flash.clear();

    const request: PaidTicketGeneralGetRequest = {
      searchShopId:
        this.paidTicketGeneralInputOptions.selectShop != null
          ? Number(this.paidTicketGeneralInputOptions.selectShop.id)
          : null,
      searchTicketId:
        this.paidTicketGeneralInputOptions.selectTicket != null
          ? this.paidTicketGeneralInputOptions.selectTicket.id
          : null
    };

    await PaidTicketGeneralGet.get(request);
    if (!PaidTicketGeneralGet.isSuccess) {
      await Flash.setErrorNow({
        message: PaidTicketGeneralGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
      return false;
    }
    await PaidTicketGeneralGet.updateItem(PaidTicketGeneralGet.getItem);
    this.isLoadedPaidTicketGeneralData = true;
    return PaidTicketGeneralGet.isSuccess;
  }

  tableDataSuffix(value: string) {
    let suffix = "";
    if (value == "totalCount") {
      suffix = "枚";
    } else if (value == "totalSales" || value == "unitPrice") {
      suffix = "円";
    } else if (
      value == "ticketUseRate" ||
      value == "ticketInvalidRate" ||
      value == "ticketRepurchaseRate"
    ) {
      suffix = "%";
    } else if (value == "currentUserValue" || value == "totalUserValue") {
      suffix = "人";
    }
    return suffix;
  }

  async fetchPaidTicketGeneralCSV() {
    await Flash.clear();

    const request: PaidTicketGeneralGetRequest = {
      searchShopId:
        this.paidTicketGeneralInputOptions.selectShop != null
          ? Number(this.paidTicketGeneralInputOptions.selectShop.id)
          : null,
      searchTicketId:
        this.paidTicketGeneralInputOptions.selectTicket != null
          ? this.paidTicketGeneralInputOptions.selectTicket.id
          : null
    };

    const paidTicketGeneralCSVGetResponse = await PaidTicketGeneralCSV.get(
      request
    );
    if (
      paidTicketGeneralCSVGetResponse &&
      paidTicketGeneralCSVGetResponse.statusCd !== 200
    ) {
      await Flash.setErrorNow({
        message: paidTicketGeneralCSVGetResponse.message,
        showReloadButton: false
      } as ErrorAlert);
      return false;
    }

    this.saveCSV(
      paidTicketGeneralCSVGetResponse.results.csvString,
      paidTicketGeneralCSVGetResponse.results.fileName
    );
  }
}
