import service from "@/api/service";
import { PaidTicketGeneralGetResponse } from "@/api/analysis/paid-ticket-general/response";
import { PaidTicketGeneralGetRequest } from "@/api/analysis/paid-ticket-general/request";

/**
 * デジタルチケット全体分析取得APIをコールします。
 *
 * @param getRequest デジタルチケット全体分析取得APIのリクエストボディ
 * @return PaidTicketGeneralGetResponse
 */
export async function get(getRequest: PaidTicketGeneralGetRequest) {
  const response = await service.post(
    "/analysis-paid-ticket-general",
    getRequest
  );
  return response.data as PaidTicketGeneralGetResponse;
}
