import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as PaidTicketGeneralAPI from "@/api/analysis/paid-ticket-general";
import { isSuccess } from "@/api/response";
import { PaidTicketGeneralGetRequest } from "@/api/analysis/paid-ticket-general/request";
import {
  PaidTicketGeneralGetGenderData,
  PaidTicketGeneralGetItem,
  PaidTicketGeneralGetPeriodData,
  PaidTicketGeneralGetResponse,
  PaidTicketGeneralGetShopData
} from "@/api/analysis/paid-ticket-general/response";

const MODULE_NAME = "analysis/paid-ticket-general/get";

interface FormattedPaidTicketGeneralGetItem extends PaidTicketGeneralGetItem {
  ticketUseRate: number;
  ticketInvalidRate: number;
  ticketRepurchaseRate: number;
}

/**
 *  デジタルチケット全体分析取得API（/analysis-paid-ticket-general）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: PaidTicketGeneralGetRequest = {} as PaidTicketGeneralGetRequest;
  getResponse: PaidTicketGeneralGetResponse = {} as PaidTicketGeneralGetResponse;
  formattedPaidTicketGeneralGetItem: FormattedPaidTicketGeneralGetItem = {} as FormattedPaidTicketGeneralGetItem;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getItem() {
    if (this.getResponse.results) {
      return this.getResponse.results.items;
    } else {
      return {} as PaidTicketGeneralGetItem;
    }
  }

  get getFormattedItem() {
    if (this.formattedPaidTicketGeneralGetItem) {
      return this.formattedPaidTicketGeneralGetItem;
    } else {
      return {} as FormattedPaidTicketGeneralGetItem;
    }
  }

  get getGenderData() {
    if (this.getResponse.results && this.getResponse.results["genderData"]) {
      return this.getResponse.results.genderData;
    } else {
      return {} as PaidTicketGeneralGetGenderData;
    }
  }

  get getPeriodData() {
    if (this.getResponse.results && this.getResponse.results["periodData"]) {
      return this.getResponse.results.periodData;
    } else {
      return {} as PaidTicketGeneralGetPeriodData;
    }
  }

  get getShopData() {
    if (this.getResponse.results && this.getResponse.results["shopData"]) {
      return this.getResponse.results.shopData;
    } else {
      return {} as PaidTicketGeneralGetShopData;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }

  // MutationActions
  @MutationAction
  async get(getRequest: PaidTicketGeneralGetRequest) {
    const getResponse = await PaidTicketGeneralAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as PaidTicketGeneralGetResponse
    };
  }

  @MutationAction
  async updateItem(item: PaidTicketGeneralGetItem) {
    if (item == null) {
      return {
        formattedPaidTicketGeneralGetItem: {} as FormattedPaidTicketGeneralGetItem
      };
    }
    const formattedPaidTicketGeneralGetItem = item as FormattedPaidTicketGeneralGetItem;
    const ticketUseRate = (item.useValue / item.totalTicketValue) * 100;
    formattedPaidTicketGeneralGetItem.ticketUseRate =
      Math.round(ticketUseRate * 10) / 10;
    const ticketInvalidRate =
      (item.invalidTicketValue / item.totalTicketValue) * 100;
    formattedPaidTicketGeneralGetItem.ticketInvalidRate =
      Math.round(ticketInvalidRate * 10) / 10;
    const ticketRepurchaseRate =
      (item.multipleUserValue / item.totalUserValue) * 100;
    formattedPaidTicketGeneralGetItem.ticketRepurchaseRate =
      Math.round(ticketRepurchaseRate * 10) / 10;
    return {
      formattedPaidTicketGeneralGetItem
    };
  }
}
export default getModule(Get);
